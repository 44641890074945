<template>
  <div class="container">
    <div class="tabs">
      <div class="tab active">
        パチンコ店情報
      </div>
      <div class="tab">
        <NuxtLink :to="{ name: 'machines.html' }">
          パチンコゲーム機
        </NuxtLink>
      </div>
    </div>
    <div class="list-wrapper" v-if="listData?.groups">
      <template v-for="g in listData.groups">
        <div class="title-lv1">
          <span>{{ g.ared }}の最新パチンコ店</span>
          <NuxtLink class="more" :title="`${g.ared}の最新パチンコ店`" :to="{ name: 'store-home-area-page.html', params: { area: g.ared, page: 1 } }">更多</NuxtLink>
        </div>
        <Shops class="list" :list="g.shops" v-if="g?.shops" />
      </template>
      <!-- <template v-if="listData?.shops?.length">
        <Shops class="list" :list="listData.shops" v-if="listData?.shops" />
        <NuxtLink class="more-btn" :to="{ name: 'store-list.html', query: { sort: useRoute().query.sort, area: useRoute().query.area } }">もっと見る</NuxtLink>
      </template>
      <template v-else>
        <div class="no-data">
          <span>店舗情報はありません</span>
        </div>
      </template> -->
    </div>
  </div>
</template>

<script setup>
import Shops from "@/components/Shops.vue";
const sorts = ["recommend", "hot", "new"];
const sortsLabels = ["おすすめ", "人気", "新着"];

const listData = reactive({
  groups: [],
})

async function getData () {
  const queryObj = {
    'pagination[page]': 1,
    'pagination[pageSize]': 100,
    'populate[shops][fields][0]': 'id',
    'populate[shops][fields][1]': 'name',
    'populate[shops][fields][2]': 'imgs',
    'populate[shops][fields][3]': 'address',
    'populate[shops][fields][4]': 'fees',
    'populate[shops][fields][5]': 'storeenv',
    'populate[shops][fields][6]': 'smokeenv',
  }
  const queryStr = Object.entries(queryObj).map(c => c.join('=')).join('&')
  const groups = await $fetch(`/api/aredhotshops?${queryStr}`).then(res => {
    return res.groups.data
  }).catch(e => {
    return null
  })
  return { groups }
}

const asData = await useAsyncData(async () => {
  const data = await getData()
  return data
})

listData.groups = asData.data.value.groups

useHead({
  title: "パチンコワールド_日本最大のパチンコネットサービスナビサイト",
  meta: [
    {
      name: "keywords",
      content: "パチンコ，全国パチンコオフライン店舗ナビ，パチンコ事典，パチンコゲームプレイガイド",
    },
    {
      name: "description",
      content: "日本最大のパチンコ オンライン サービス ウェブサイト、パチンコ、全国のパチンコ オフライン ストア ナビゲーション、パチンコ百科事典、パチンコ ゲームプレイ ガイドは、ここパチンコ ワールドで日本最大かつ最も包括的なパチンコ オンライン サービスを提供しています。",
    },
  ],
});
</script>

<style lang="less" scoped>
.container {
  margin: 0 auto;
  width: 80%;
}
.tabs {
  padding-top: 30px;
  display: flex;
  position: sticky;
  top: 30px;
  left: 0;
  z-index: 9;
  background-color: #fff;
  border-bottom: solid 1px #000;
  .tab {
    font-size: 18px;
    flex: 1 1 auto;
    padding: 10px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    color: #333;
    max-width: 200px;
    &.active {
      border-bottom: solid 3px #000;
    }
    a {
      text-decoration: none;
      color: #333;
    }
  }
}
.list-wrapper {
  min-height: 60vh;
}
.filter {
  margin-top: 10px;
  background: #f6f6f6;
  padding: 10px 20px;
  &-item {
    .label {
      font-weight: bold;
    }
    .value {
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0;
      .radio {
        display: block;
        margin: 3px 12px 3px 0;
        padding: 2px 5px;
        min-width: 56px;
        text-align: center;
        border-radius: 2px;
        box-sizing: content-box;
        border: solid 1px #ccc;
        text-decoration: none;
        color: #000;
        user-select: none;
        &:hover,
        &.active {
          border-color: #ffb60c;
          background: #ffb60c;
        }
      }
    }
  }
}
.sort-tabs {
  display: flex;
  // justify-content: flex-end;
  padding: 10px;
  .sort-tab {
    flex: 0 0 auto;
    text-align: center;
    font-weight: bold;
    user-select: none;
    padding: 0 10px;
    color: #333;
    &:not(:last-child) {
      border-right: solid 1px #000;
    }
    a {
      text-decoration: none;
      color: #333;
    }
    &.active a, a:hover {
      color: #ffb60c;
    }
  }
}
.title-lv1 {
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0;
  border: solid 1px #000;
  color: #000;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 111px;
  background-color: #fff;
  z-index: 9;
}
.title-lv2 {
  font-size: 14px;
  font-weight: bold;
  margin: 15px 0;
  padding: 5px 8px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 8;
  top: 145px;
}
.more {
  font-size: 14px;
  color: #999;
  text-align: center;
  &:hover {
    color: #000;
  }
}
.more-btn {
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0;
  border: solid 1px #000;
  color: #000;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.no-data {
  margin: 50px 0 70px;
  text-align: center;
  font-size: 18px;
  color: #999;
}
</style>
